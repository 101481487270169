import React, { useEffect, useState } from 'react'
import './privacy.css'
import i18next from 'i18next'
import { tabTitle } from '../..'

const SellerPrivacy = () => {
  const [lang, setLang] = useState('fr')

  tabTitle("Politique de confidentialité de HooYia")
  useEffect(() => {
    setLang(i18next.language)
  }, [])
  
  return (
    <>
    {
      lang === 'en'
      ?
      <PrivacyEN />
      :
      <PrivacyFR />
    }
    </>
  )
}

export default SellerPrivacy


const PrivacyEN = () => {
  return (
    <div className='container privacy_policy'>
      <h1>PRIVACY POLICY</h1> 
          <p>Last updated on 11th February 2023</p>

          <p>This privacy notice for HooYia ("we," "our," or "us") describes how and why we may collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:</p>
      <ul>
          <li>Visit and use our website (https://e-hooyia.com)</li>
          <li>Interact with us in any other way, including any sales, marketing, or events.</li>
      </ul>

      <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have questions or concerns, please contact us at <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>

      <h2>SUMMARY OF KEY POINTS</h2>
      <p>The following summary provides the key points of our privacy notice, but you can get more details on each of these topics by reading the full privacy notice below.</p>

      <ol>
          <li><a href="#section1">SELLING BOOK ON HOOYIA</a></li>
          <li><a href="#section2">WITHDRAWAL PROCESS</a></li>
          <li><a href="#section3">WHAT INFORMATION DO WE COLLECT?</a></li>


          <li><a href="#section4">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
          <li><a href="#section5">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
          <li><a href="#section6">HOW LONG DO WE RETAIN YOUR INFORMATION?</a></li>
          <li><a href="#section7">HOW DO WE PROTECT YOUR INFORMATION?</a></li>
          <li><a href="#section8">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
          <li><a href="#section9">CONTROLS FOR "DO NOT TRACK" FEATURES</a></li>
          <li><a href="#section10">DO WE UPDATE THIS NOTICE?</a></li>
          <li><a href="#section11">HOW CAN YOU CONTACT US REGARDING THIS NOTICE?</a></li>
          <li><a href="#section12"> HOW CAN YOU ACCESS, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
          <li><a href="#section13">FRAUD AND AUTHENTICITY</a></li>
      </ol>



      <h2 id="section1">1. SELLING BOOK ON HOOYIA</h2>
      <ul>
        <li>We offer a book selling program where sellers can list their books for sale on our platform.</li>
        <li>When a book is purchased, we pay 90% of the book's price to the seller (owner of the book).</li>
        <li>The remaining 10% is retained by HooYia as our commission for facilitating the sale.</li>
        <li>Please note that these percentages are subject to change at any time without prior notice.</li>
      </ul>

      <h2 id="section2">2. WITHDRAWAL PROCESS</h2>
      <ul>
          <li>Sellers can withdraw their funds after 5 working days if the customer (person who buys the book) does not request a refund.</li>
          <li>To initiate a withdrawal, sellers need to have a minimum balance of 500 XAF / $10 USD in their account.</li>
          <li>The withdrawal process allows sellers to transfer their earnings to their designated payment method, such as a bank account or PayPal.</li>
      </ul>



      <h2 id="section3">3. WHAT INFORMATION DO WE COLLECT?</h2>
      <p>Personal information you provide to us</p>

      <p>In summary, We collect personal information that you provide to us.</p>

      <p>We collect personal information that you voluntarily provide to us when you register for the Services, express an interest in obtaining information about us or our products and services, participate in activities on the Services, or contact us in any other way.</p>

      <p>The personal information we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
      <ul>
          <li>User Names</li>
          <li>User identifiers</li>
          <li>User Email addresses</li>
          <li>Phone numbers</li>
          <li>Addresses and location</li>
          <li>Passwords</li>
          <li>Bank information</li>
          <li>Mobile money information</li>
      </ul>

      <p>Certain information, such as your IP address and/or certain characteristics of your web browser, is automatically collected when you visit our Services.</p>


      <h2 id="section4">4. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      <p>In summary, We process your information for the following purposes</p>
      <ul>
          <li>To provide you with the Services</li>
          <li>To send you marketing communications</li>
          <li>To administer and manage our operations</li>
          <li>To respond to your requests or inquiries</li>
          <li>To improve our services or develop new products</li>
          <li>To detect and prevent fraud</li>
          <li>To ensure legal compliance</li>
      </ul>


      <h2 id="section5">5. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
      <p>In simmary, We only share your personal information with your consent or in certain limited circumstances. However, some of this information is available on your store for you clients to access. this prevents frauds and communicates transparentcy on our platform</p>

      <p>We do not sell or rent your personal information to third parties for marketing purposes. We only share your personal information with third parties under the following circumstances</p>
      <ul>
          <li>With your consent</li>
          <li>To comply with legal obligations</li>
          <li>To protect and defend our rights and properties</li>
          <li>To respond to legal requests</li>
          <li>To fulfill our contracts</li>
          <li>To facilitate or negotiate a merger, acquisition, sale of company assets, and/or reorganization</li>
      </ul>


      <h2 id="section6">6. HOW LONG DO WE RETAIN YOUR INFORMATION?</h2>
      <p>In summary, We retain your information for as long as necessary to achieve the purposes described in this privacy notice, unless otherwise required by law.</p>

      <p>We only retain your personal information for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law (for example, for tax, accounting, or other legal reasons).</p>



      <h2 id="section7">7. HOW DO WE PROTECT YOUR INFORMATION?</h2>
      <p>In summary, We implement appropriate security measures to protect your personal information.</p>

      <p>We have implemented appropriate security measures to prevent any loss, misuse, unauthorized access, disclosure, alteration, or destruction of your personal information.</p>



      <h2 id="section8">8.  WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>In summary, You have certain privacy rights, such as the right to request access, rectification, deletion, or limitation of the use of your personal information.</p>

      <p> you have rights regarding your personal information, such as the right to access, correct, or delete the personal information we hold about you. It's important to note that these rights apply to all users, and we should respect and facilitate the exercise of these rights whenever they want, either through self-service options provided by the organization or by contacting their privacy or customer support teams.</p>



      <h2 id="section9">9.  CONTROLS FOR "DO NOT TRACK" FEATURES</h2>
      <p>Most web browsers and some mobile operating systems include a "Do Not Track" ("DNT") feature or setting that you can activate to indicate your privacy preference, requesting that your online browsing activity data not be tracked and collected</p>

      <p>Currently, we do not respond to DNT signals or other similar mechanisms.</p>



      <h2 id="section10">10. DO WE UPDATE THIS NOTICE?</h2>
      <p>In summary, Yes, we will update this notice as needed to stay in compliance with applicable laws</p>

      <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated revision date, and the updated version will be effective as soon as it is accessible. If we make significant changes to this privacy notice, we may inform you by prominently posting a notice of such changes or by directly sending you the updated notice. We encourage you to regularly review this privacy notice to stay informed about how we protect your information.</p>



      <h2 id="section11">11. HOW CAN YOU CONTACT US REGARDING THIS NOTICE?</h2>
      <p>If you have any questions or comments about this notice, you can contact our Data Protection Officer by email at <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>


      <h2 id="section12">12. HOW CAN YOU ACCESS, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
      <p>In summary, If you have an account with us, you can access and update your personal information by logging into your account or contacting us</p>
      

      <p>If you have an account with us, you can access and update certain personal information by logging into your account and accessing the account settings. You can also contact us directly to access, update, or delete your personal information. We will respond to your request in a reasonable timeframe.</p>

      <h2 id="section13">13. FRAUD AND AUTHENTICITY</h2>
      <p>Sellers are restricted from posting copywritted content on the platform. All books being published should be created by user or granted by the author of the book before being published. If a seller tries to sell a book which they did not create, the account will be disabled immediately</p>
      <hr />

      <p>Thank you for reading our privacy notice. If you have any questions or concerns about our privacy practices or your personal information, please contact us at the following address: <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>

    </div>
  )
}





const PrivacyFR = () => {
  return (
    <div className='container privacy_policy'>
      <h1> POLITIQUE DE CONFIDENTIALITÉ </h1>
      <p> Dernière mise à jour le 11 février 2023 </p>

      <p> Cet avis de confidentialité pour HooYia ("nous," "notre" ou "nos") décrit comment et pourquoi nous pouvons collecter, stocker, utiliser et/ou partager ("traiter") vos informations lorsque vous utilisez nos services ("Services"), tels que lorsque vous :</p>
      <ul>
        <li> Visitez et utilisez notre site web (https://e-hooyia.com) </li>
        <li> Interagissez avec nous de toute autre manière, y compris les ventes, le marketing ou les événements. </li>
      </ul>

      <p> Des questions ou des préoccupations ? La lecture de cet avis de confidentialité vous aidera à comprendre vos droits et choix en matière de confidentialité. Si vous n'êtes pas d'accord avec nos politiques et pratiques, veuillez ne pas utiliser nos Services. Si vous avez encore des questions ou des préoccupations, veuillez nous contacter à l'adresse suivante : <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>

      <h2>RÉSUMÉ DES POINTS CLÉS</h2>
      <p>Le résumé suivant fournit les points clés de notre avis de confidentialité, mais vous pouvez obtenir plus de détails sur chacun de ces sujets en lisant l'avis de confidentialité complet ci-dessous.</p>

      <ol>
        <li><a href="#section1">VENDRE UN LIVRE SUR HOOYIA</a></li>
        <li><a href="#section2">PROCESSUS DE RETRAIT</a></li>
        <li><a href="#section3">QU'ELLES INFORMATIONS COLLECTONS-NOUS ?</a></li>
        <li><a href="#section4">COMMENT TRAITONS-NOUS VOS INFORMATIONS ?</a></li>
        <li><a href="#section5">QUAND ET AVEC QUI PARTAGEONS-NOUS VOS INFORMATIONS PERSONNELLES ?</a></li>
        <li><a href="#section6">COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?</a></li>
        <li><a href="#section7">COMMENT PROTÉGEONS-NOUS VOS INFORMATIONS ?</a></li>
        <li><a href="#section8">QU'EN EST-IL DE VOS DROITS EN MATIÈRE DE CONFIDENTIALITÉ ?</a></li>
        <li><a href="#section9">COMMANDES POUR LES FONCTIONNALITÉS "NE PAS SUIVRE"</a></li>
        <li><a href="#section10">METTONS-NOUS À JOUR CET AVIS ?</a></li>
        <li><a href="#section11">COMMENT POUVEZ-VOUS NOUS CONTACTER CONCERNANT CET AVIS ?</a></li>
        <li><a href="#section12">COMMENT POUVEZ-VOUS ACCÉDER, METTRE À JOUR OU SUPPRIMER LES DONNÉES QUE NOUS COLLECTONS DE VOUS ?</a></li>
        <li><a href="#section13">13. FRAUDE ET AUTHENTICITÉ</a></li>
      </ol>

      <h2 id="section1">1. VENTE DE LIVRE SUR HOOYIA</h2>
      <ul>
        <li>Nous proposons un programme de vente de livres où les vendeurs peuvent répertorier leurs livres à vendre sur notre plateforme.</li>
        <li>Lorsqu'un livre est acheté, nous versons 90 % du prix du livre au vendeur (propriétaire du livre).</li>
        <li>Les 10 % restants sont conservés par HooYia en tant que commission pour faciliter la vente.</li>
        <li>Veuillez noter que ces pourcentages peuvent changer à tout moment sans préavis.</li>
      </ul>

      <h2 id="section2">2. PROCESSUS DE RETRAIT</h2>
      <ul>
        <li>Les vendeurs peuvent retirer leurs fonds après 5 jours ouvrables si le client (personne qui achète le livre) ne demande pas de remboursement.</li>
        <li>Pour initier un retrait, les vendeurs doivent avoir un solde minimum de 500 XAF / $10 USD sur leur compte.</li>
        <li>Le processus de retrait permet aux vendeurs de transférer leurs gains vers leur mode de paiement désigné, comme un compte bancaire ou PayPal.</li>
      </ul>

      <h2 id="section3">3. QUELLES INFORMATIONS COLLECTONS-NOUS ?</h2>
      <p>Les informations personnelles que vous nous fournissez</p>

      <p>En résumé, nous collectons des informations personnelles que vous nous fournissez.</p>

      <p>Nous collectons des informations personnelles que vous nous fournissez volontairement lorsque vous vous inscrivez pour les Services, exprimez votre intérêt pour obtenir des informations sur nous ou nos produits et services, participez à des activités sur les Services ou nous contactez de quelque autre manière.</p>

      <p>Les informations personnelles que nous collectons dépendent du contexte de vos interactions avec nous et les Services, des choix que vous faites, et des produits et fonctionnalités que vous utilisez. Les informations personnelles que nous collectons peuvent inclure les éléments suivants :</p>
      <ul>
        <li>Noms d'utilisateur</li>
        <li>Identifiants d'utilisateur</li>
        <li>Adresses e-mail d'utilisateur</li>
        <li>Numéros de téléphone</li>
        <li>Adresses et emplacements</li>
        <li>Mots de passe</li>
        <li>Informations bancaires</li>
        <li>Informations de transfert d'argent mobile</li>
      </ul>

      <p>Certaines informations, telles que votre adresse IP et/ou certaines caractéristiques de votre navigateur Web, sont automatiquement collectées lorsque vous visitez nos Services.</p>


      <h2 id="section4">4. COMMENT TRAITONS-NOUS VOS INFORMATIONS ?</h2>
      <p>En résumé, nous traitons vos informations aux fins suivantes :</p>
      <ul>
        <li>Pour vous fournir les Services</li>
        <li>Pour vous envoyer des communications marketing</li>
        <li>Pour administrer et gérer nos opérations</li>
        <li>Pour répondre à vos demandes ou questions</li>
        <li>Pour améliorer nos services ou développer de nouveaux produits</li>
        <li>Pour détecter et prévenir la fraude</li>
        <li>Pour garantir la conformité légale</li>
      </ul>

      <h2 id="section5">5. QUAND ET AVEC QUI PARTAGEONS-NOUS VOS INFORMATIONS PERSONNELLES?</h2>
      <p>En résumé, nous ne partageons vos informations personnelles qu'avec votre consentement ou dans certaines circonstances limitées. Cependant, certaines de ces informations sont disponibles sur votre boutique pour que vos clients puissent y accéder. Cela permet de prévenir les fraudes et de communiquer en toute transparence sur notre plateforme.</p>

      <p>Nous ne vendons pas ou ne louons pas vos informations personnelles à des tiers à des fins de marketing. Nous ne partageons vos informations personnelles avec des tiers que dans les circonstances suivantes :</p>
      <ul>
        <li>Avec votre consentement</li>
        <li>Pour respecter les obligations légales</li>
        <li>Pour protéger et défendre nos droits et nos biens</li>
        <li>Pour répondre aux demandes légales</li>
        <li>Pour remplir nos contrats</li>
        <li>Pour faciliter ou négocier une fusion, une acquisition, une vente d'actifs de l'entreprise et/ou une réorganisation</li>
      </ul>


      <h2 id="section6">6. PENDANT COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS?</h2>
      <p>En résumé, nous conservons vos informations aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cette politique de confidentialité, sauf disposition contraire de la loi.</p>

      <p>Nous ne conservons vos informations personnelles que pendant la durée nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées, sauf si une période de conservation plus longue est requise ou autorisée par la loi (par exemple, pour des raisons fiscales, comptables ou autres raisons légales).</p>


      <h2 id="section7">7. COMMENT PROTÉGEONS-NOUS VOS INFORMATIONS ?</h2>
      <p>En résumé, nous mettons en place des mesures de sécurité appropriées pour protéger vos informations personnelles.</p>

      <p>Nous avons mis en place des mesures de sécurité appropriées pour prévenir toute perte, mauvaise utilisation, accès non autorisé, divulgation, altération ou destruction de vos informations personnelles.</p>


      <h2 id="section8">8. QUELS SONT VOS DROITS EN MATIÈRE DE CONFIDENTIALITÉ ?</h2>
      <p>En résumé, vous disposez de certains droits en matière de confidentialité, tels que le droit de demander l'accès, la rectification, la suppression ou la limitation de l'utilisation de vos informations personnelles.</p>

      <p>Vous avez des droits concernant vos informations personnelles, tels que le droit d'accéder, de corriger ou de supprimer les informations personnelles que nous détenons à votre sujet. Il est important de noter que ces droits s'appliquent à tous les utilisateurs, et nous devons respecter et faciliter l'exercice de ces droits chaque fois qu'ils le souhaitent, que ce soit par le biais d'options en libre-service fournies par l'organisation ou en contactant leurs équipes de confidentialité ou de support client.</p>


      <h2 id="section9">9. CONTRÔLES POUR LES FONCTIONNALITÉS "NE PAS SUIVRE"</h2>
      <p>La plupart des navigateurs Web et certains systèmes d'exploitation mobiles incluent une fonctionnalité ou un paramètre "Ne pas suivre" ("DNT") que vous pouvez activer pour indiquer vos préférences en matière de confidentialité, demandant que vos données d'activité de navigation en ligne ne soient pas suivies et collectées.</p>

      <p>Actuellement, nous ne répondons pas aux signaux DNT ou à d'autres mécanismes similaires.</p>


      <h2 id="section10">10. METTONS-NOUS À JOUR CET AVIS ?</h2>
      <p>En résumé, oui, nous mettrons à jour cet avis au besoin pour rester conforme aux lois applicables.</p>

      <p>Nous pouvons mettre à jour cet avis de confidentialité de temps à autre. La version mise à jour sera indiquée par une date de révision mise à jour, et la version mise à jour sera effective dès qu'elle sera accessible. Si nous apportons des modifications importantes à cet avis de confidentialité, nous pouvons vous informer en publiant de manière visible un avis de ces modifications ou en vous envoyant directement l'avis mis à jour. Nous vous encourageons à consulter régulièrement cet avis de confidentialité pour vous tenir informé de la manière dont nous protégeons vos informations.</p>


      <h2 id="section11">11. COMMENT POUVEZ-VOUS NOUS CONTACTER CONCERNANT CET AVIS ?</h2>
      <p>Si vous avez des questions ou des commentaires concernant cet avis, vous pouvez contacter notre responsable de la protection des données par e-mail à l'adresse suivante : <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>


      <h2 id="section12">12. COMMENT POUVEZ-VOUS ACCÉDER, METTRE À JOUR OU SUPPRIMER LES DONNÉES QUE NOUS COLLECTONS À VOTRE SUJET ?</h2>
      <p>En résumé, si vous avez un compte chez nous, vous pouvez accéder et mettre à jour vos informations personnelles en vous connectant à votre compte ou en nous contactant.</p>

      <p>Si vous avez un compte chez nous, vous pouvez accéder et mettre à jour certaines informations personnelles en vous connectant à votre compte et en accédant aux paramètres du compte. Vous pouvez également nous contacter directement pour accéder, mettre à jour ou supprimer vos informations personnelles. Nous répondrons à votre demande dans un délai raisonnable.</p>

      <h2 id="section13">13. FRAUDE ET AUTHENTICITÉ</h2> 
      <p>Les vendeurs sont interdits de publier du contenu protégé par des droits d'auteur sur la plateforme. Tous les livres publiés doivent être créés par l'utilisateur ou autorisés par l'auteur du livre avant d'être publiés. Si un vendeur tente de vendre un livre qu'il n'a pas créé, le compte sera immédiatement désactivé.</p>

      <hr />

      <p>Merci d'avoir lu notre avis de confidentialité. Si vous avez des questions ou des préoccupations concernant nos pratiques en matière de confidentialité ou vos informations personnelles, veuillez nous contacter à l'adresse suivante : <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>
    


    </div>
  )
}

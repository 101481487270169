import React, { useEffect, useLayoutEffect, useState } from 'react'
import './Ebook.css'
import ProductGrid from '../../Products/ProductGrid'
import Search from '../../Addons/Search/Search'
import { getRequest, getRequestWithParams } from '../../../Resquest'

import { InstantSearch, RefinementList, Hits, SearchBox } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import { useTranslation } from 'react-i18next'
import { searchClient } from '../../../searchClient'
import Pagination from '../../Addons/Pagination/pagination';

import { ReactComponent as EbooksSvg } from '../../../Assets/img/ebook_svg.svg';
import LoadingPage from '../../SELLER/Dashboard/Components/LoadingPage'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { setMetaDescription } from '../../..'
import { usePagination } from "../../../Context/PaginationContext";


const Ebooks = () => {
	const [ebooks, setEbooks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [bookCount, setBookCount] = useState(0);
	const { limit, offset, setTotalItems } = usePagination();
	const location = useLocation();

	useEffect(() => {
		getBookWithCat();
	}, [location, offset]);


	const getBookWithCat = async () => {
		setIsLoading(true);
		let response;
		const category_id = location.state?.dir;

		try {
			response = category_id
				? await getRequest(
					`/public/ebook/display/?categories=${category_id}&limit=${limit}&offset=${offset}`
				)
				: await getRequest(`/public/ebook/display/?limit=${limit}&offset=${offset}`);

			if (response.statusText === "OK") {
				saveData(response.data);
			}
		} catch (error) {
			console.error("erreur lors de la recuperation des Ebooks");
		} finally {
			setIsLoading(false);
		}
	};


	const saveData = (ebook_data) => {
		setEbooks(ebook_data?.results || []);
		setBookCount(ebook_data?.count || 0);
		setTotalItems(ebook_data?.count || 0);
	};

	const { t } = useTranslation();

	const queryHook = (query, search) => {
		search(query);
		query.length > 0 ? setIsSearching(true) : setIsSearching(false);
	};

	useEffect(() => {
		const bookDescription = t("randoms.ebook_description");
		const ogBookDescription = t("randoms.ebook_description");

		setMetaDescription(bookDescription, ogBookDescription);

		return () => {
			setMetaDescription("", "");
		};
	}, []);

	return (
		<InstantSearch searchClient={searchClient} indexName="hooyia_index_ebook" insights={true}>
			<div className="ebook_n">
				<div className="container">
					<SearchBox
						queryHook={queryHook}
						className="search_n_user"
						placeholder="search..."
					/>
					<div className="ebook_grid_n">
						<div className={`ebook_list_n ${isSearching ? "showaside" : ""} `}>
							{isSearching ? (
								<div className="search_result_non_user">
									<Hits hitComponent={Hit} />
								</div>
							) : (
								<div className="no_search_aside">
									<div className="title">{t("non_user_ebooks.title")}</div>
									<p>{t("non_user_ebooks.text")}</p>
									<div className="svg_login">
										<EbooksSvg />
									</div>
									<p>{t("non_user_ebooks.more_text")}</p>
								</div>
							)}
						</div>
						<div className="ebook_subgrid_n">
							{isLoading ? <LoadingPage /> : <ProductGrid products={ebooks} />}
						</div>
					</div>
					<div className="pagination_btn_wrapper">
						<Pagination />
					</div>
				</div>
			</div>
		</InstantSearch>
	);
};

export default Ebooks;


const Hit = ({ hit }) => {
	const navigate = useNavigate()
	return (
		<div onClick={() => navigate(`/ebooks/${hit?.objectID}`)}>
			{/* <hit attribute="brand" /> */}
			<div className='hit_grid'>
				<div className='hit_item_image'>
					<img className='img' src={'https://e-hooyia.s3.amazonaws.com/' + hit?.cover} alt={hit?.name} />
				</div>
				<div className='hit_desc'>
					<p className='hit_title'>{hit?.name}</p>
					<p className='hit_auth'>By: {hit?.author}</p>
				</div>
			</div>
		</div>
	)
}
import React, { useEffect, useLayoutEffect, useState } from 'react'
import {cubicBezier, motion, stagger, useAnimate, useDragControls, usePresence, useSpring} from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { LazyLoadImage } from "react-lazy-load-image-component";
import './Home.css'
import { Link } from 'react-router-dom'
import GirlReading from '../../../Assets/img/landing/GirlReading.jpg'
import BebeReading from '../../../Assets/img/landing/BebeReading.jpg'
import BabyEbook from '../../../Assets/img/landing/BabyEbook.jpg'
import ManEbook from '../../../Assets/img/landing/ManEbook.jpg'
import EbookCoffee from '../../../Assets/img/landing/EbookCoffee.jpg'
import PopularReviews from './Carousel/PopularReviews';
import CategoryCarousel from './Carousel/CategoryCarousel';
import HappGirl from '../../../Assets/img/pretty-young.png';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import axios from 'axios';
import Product from '../../Products/Product';
import ProductGrid from "../../Products/ProductGrid";
import { setMetaDescription } from '../../..';

const Home = () => {
  const [scopeBall, animate] = useAnimate(LazyLoadImage);
  const [isPresence, safeToRemove] = usePresence();
  const [books, setBooks] = useState([]);
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dragcontrols = useDragControls()
  
    useEffect(() => {
      if(isPresence){
        loadAnimation()
      }
    }, []);
    
    const loadAnimation = async() => {
        await animate( 
          'div',
          {opacity: [0, 1], scale: [0, 1.5] }, 
          { duration: 0.5, delay: stagger( 0.2),
            easing: 'ease-in-out'
          }
        )

        setTimeout(() => {
          animate(
            'div',
            { translateX: ['0px', '20px', '15px'],
              translateY: ['0px', '-17px'] },
            { duration: 3 }
          );
        }, 300);
    }
    
    useLayoutEffect(() => {
      getRequirements()
    }, [])

    const getRequirements = async () => {
        setIsLoading(true);

        let URL1 = "https://hooyia.herokuapp.com/api/v1/public/ebook/display/";
        let URL2 = "https://hooyia.herokuapp.com/api/v1/public/list-category/";

        const promise1 = axios.get(URL1);
        const promise2 = axios.get(URL2);

        try {
            const [booksResponse, categoriesResponse] = await Promise.all([
                promise1,
                promise2,
            ]);

            const allBooks = booksResponse.data?.results || [];
            setBooks(allBooks.slice(0, 10));
            setCategory(categoriesResponse.data?.results || []);
        } catch (error) {
            console.error("Erreur lors de la récupération des données");
        } finally {
            setIsLoading(false);
        }
    };


    const { t } = useTranslation();
    
  return (
    <div className='landingPage'>
      <div className='container'>
        <div className='app_heroSection'>
          <div className='content'>
            <h2 className='hero-main-title'>{t('landing.hero.welcome.first')} <span>{t('landing.hero.welcome.second')}</span>{t('landing.hero.welcome.third')}<span>{t('landing.hero.welcome.forth')}</span></h2>
            <p className='landing_desc'>{t('landing.hero.hero_text')}</p>
            <Link to='/ebooks?auth=false' className="submitBtn mobile_call_to_action_2">{t('landing.hero.get_started')}</Link>
            <Link to='/register' className="submitBtn submitBtn_login mobile_call_to_action_sec">{ t("randoms.register") }</Link>
          </div>
          <div className='hero_img_wrapper'>
            <div ref={ scopeBall } className='hero_balls_container'>
              <div className='hero_ball'>
                <motion.div
                  drag
                  dragControls={dragcontrols}
                  style={{ marginTop: 10 }}
                  className='animate_ball'
                  dragConstraints={{
                  top: 100,
                  left: -100,
                  right: 100,
                  bottom: 100,
                }}
                >
                  <LazyLoadImage src={BebeReading} delayTime={2000}
                    alt="Image Alt"
                  />
                </motion.div>
                <motion.div
                  drag
                  dragControls={dragcontrols}
                  className='animate_ball ball_two desktop'
                  dragConstraints={{
                  top: 100,
                  left: -100,
                  right: 100,
                  bottom: 100,
                }}
                >
                  <LazyLoadImage src={ManEbook} delayTime={5000}
                    alt="Image Alt"
                  />
                </motion.div>
                <motion.div
                  drag
                  dragControls={dragcontrols}
                  className='animate_ball ball_three'
                  dragConstraints={{
                  top: 100,
                  left: -100,
                  right: 100,
                  bottom: 100,
                }}
                >
                  <LazyLoadImage src={BabyEbook} delayTime={8000}
                    alt="Image Alt"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        <RecommendedSellers books={ books } isLoading={isLoading}/>
        <BuildFuture />
        <BringKnowledge />
        <BookCategories categories={category} />
        <PopularReviews />
        <div className='landing_banner'>
          <div className='content'>
            <h3>{ t("testimonial.join_us")}</h3>
            <p className='landing_desc'>{ t("testimonial.card")}</p>
            <Link to='/ebooks?auth=false' className="submitBtn">{ t('landing.hero.get_started') }</Link>
          </div>
          <div className='landing_banner_img'>
            <img src={ HappGirl } style={{ marginRight: 20 }} alt='hooyia happy girl register' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home

const RecommendedSellers = ({ books, isLoading }) => {

  return (
      <section className="popular_sellers landing_book_category">
        <h2 className="landing_title_center">{t("landing.titles.pop_seller")}</h2>
        <div className="landing_category_scroll_wrapper">
          {isLoading ? (
             <ProductGrid products={books} isloading={isLoading} />
          ) : (
              <div className="landing_category_scroll">
                <div className="home_grid">
                  {books.map((book) => (
                      <Product key={book.id} product={book} />
                  ))}
                </div>
              </div>
          )}
        </div>
      </section>
  );
}



const BuildFuture = () => {
  const {ref, inView, entry} = useInView({
    threshold: 0,
  });
  return (
      <section className='landing_section_flex'>
        <div ref={ref} className={`content ${inView ? 'animateOne': ''}`}>
          <h2>{ t('landing.paragraphs.one.title') }</h2>
          <p className='landing_desc'>{ t('landing.paragraphs.one.text') }</p>
        </div>
        <div ref={ref} className={`landing_img_wrapper ${inView ? 'animateOne': ''}`}>
            <img src={GirlReading} alt='girl reading book' />
        </div>
      </section>
  )
}

const BringKnowledge = () => {
  const { ref, inView, entry } = useInView({
          threshold: 0,
  });
  return (
    <section ref={ref} className={`landing_section_flex bring_know_section ${inView ? 'animateOne': ''}`} >
        <div ref={ref} className={`landing_img_wrapper ${inView ? 'animateOne': ''}`}>
            <img src={EbookCoffee} alt='girl reading book' />
        </div>
        <div ref={ref} className={`content ${inView ? 'animateOne': ''}`}>
          <h2>{ t('landing.paragraphs.two.title') }</h2>
          <p className='landing_desc'>{ t('landing.paragraphs.two.text') }</p>
        </div>
      </section>
  )
}


const BookCategories = ({ categories }) => {
  const { ref, inView, entry } = useInView({
          threshold: 0,
  });
  return (
    <section  className='landing_book_category'>
        <h2 className='landing_title_center'>{ t('landing.titles.pop_cat') }</h2>
        <div className='landing_category_scroll_wrapper'>
          <div className='landing_category_scroll'>
            <CategoryCarousel categories={categories} />
            <div className='landing_category'>
            </div>
          </div>
        </div>
      </section>
  )
}


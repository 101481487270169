import React, { useRef, useState } from 'react'
import { FiShoppingCart } from 'react-icons/fi'
import { RiUser6Line } from 'react-icons/ri'
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link, NavLink } from 'react-router-dom'
import './Nav.css'
import { BsChevronDown, BsGlobe2 } from 'react-icons/bs'
import { CiGlobe } from "react-icons/ci";
import { motion, useCycle, useMotionValueEvent, useScroll } from 'framer-motion'
import { VscChromeClose, VscListSelection } from 'react-icons/vsc'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from 'i18next'
import i18next from 'i18next'
import { FeatureDropDownCategory } from '../lib/FeatureDropDown'
import logo from '../../../Assets/img/logo512.png';
import Logo from "../../../Assets/img/logo512.png";

const Nav = ({ category_list, fetching_cat }) => {
        const [isOpen, toggleOpen] = useCycle(false, true);
        const containerRef = useRef(null);
        // const { height } = useDimensions(containerRef);

        const [showNav, setShowNav] = useState(false);
        const [changeNavColor, setChangeNavColor] = useState(false);
        const [langMobile, setLangMobile] = useState(false)
        
 const languages = [
        { name: "English", code: 'en', flag: 'gb' },
        { name: "Français", code: 'fr', flag: 'fr' }
    ];

    const { scrollY } = useScroll();
    useMotionValueEvent(scrollY, "change", (distance) => {
        setChangeNavColor(distance < 20);
    });

    const handleRemoveNav = (e) => {
        e.target.className === 'nav_link_n' && setShowNav(false);
    };

    const changeLang = (code) => {
        i18next.changeLanguage(code);
        window.location.reload();
    };

    const { t } = useTranslation();
  return (
    <div className='non_user_nav'>
        <div className='mobile_nav_n' >
                <div className= {`mobile-top ${ showNav ? 'white-logo' : ''}`} >
                        <div className='logo-burger center'>
                            <a href="/">
                                <span className='logo-base'>HooYia</span>
                            </a>
                        </div>
                    <div>
                        </div>
                </div>
                <motion.span 
                        drag="x"
                        dragConstraints={{ left: 0, right: 20}} 
                        onDragEnd={() =>  setShowNav(!showNav)}
                        className={`burger ${showNav ? 'showBurger': ''}`}
                >
                        {
                                !showNav && (
                                <div className='lang_change_mobile'>
                                        <div className='the_lang_btn' onClick={() => setLangMobile(!langMobile)}><CiGlobe /></div>
                                        {
                                                langMobile && (
                                                <div className='dropdown_mobile_lang_content'>
                                                <ul>
                                                {
                                                        languages.map((language, index) => (
                                                        <li key={index} className="dropdownNav-item">
                                                                <span  onClick={(e) => changeLang(language.code, e)} className={`flag-icon flag-icon-${language.flag}`}></span>
                                                                <span  onClick={(e) => changeLang(language.code, e)} className='blacktext'>{language.name}</span>
                                                        </li>
                                                        ))
                                                }
                                                </ul> 
                                                </div>
                                                )
                                        }
                                </div>
                                )
                        }
                        <span className='burger_self' onClick={() => setShowNav(!showNav)}>
                                {
                                        showNav ?
                                        <VscChromeClose />
                                        :
                                        <VscListSelection />

                                }
                        </span>
                </motion.span>
                <div className={`mobile_nav_n ${showNav ? 'showNavN' : 'unclip'}`} >
                        <div className='nav_n_logo'>
                                <a href="/">
                                        <span className='logo-base whiteLogo'  >HooYia</span>
                                </a>
                        </div>
                        <ul onClick={(e) => handleRemoveNav(e)}>
                                
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to = '/ebooks?auth=false' >{ t("non_user_nav.browse") }</NavLink>
                                </li>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/explore' >{ t("non_user_nav.explore") }</Link>
                                </li>
                                {/* <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/frequently-asked-question' >FAQs</Link>
                                </li> */}
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/tour/guide' >Guides</Link>
                                </li>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/about-us' >{t("non_user_nav.about")}</Link>
                                </li>
                                 <li className='nav_list_n'>
                                        <a className='nav_link_n'
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           href='https://hooyia.net/fr/training/'>{t("non_user_nav.training")}</a>
                                        <NavLink className='nav_link_n' to = '/about-us' >{t("non_user_nav.about")}</NavLink>

                                </li>
                                <li className='nav_list_n'>
                                        <Link onClick={changeLang} className='nav_link_n' >
                                                languages ( <span  className={`flag-icon flag-icon-gb`}/> / <span className={`flag-icon flag-icon-fr`}/> )
                                        </Link>
                                </li>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/login' >{ t("non_user_nav.login") }</Link>
                                </li>
                                <li className='nav_list_n'>
                                        <Link className='nav_link_n' to = '/register' >{ t("non_user_nav.signup") }</Link>
                                </li>
                        </ul>
                </div>
        </div>
        <div className={`nav_desktop_n ${ !changeNavColor ? 'changeNavColor_n': '' }`}>
                <div className={`container nav_desk_n`}>
                    <div className='nav_n_logo'>
                        <a href="/">
                            <span className='logo-base whiteLogo'>HooYia</span>
                        </a>
                    </div>
                    <ul>
                    {/* <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to = '/' >{ t("non_user_nav.home") }</NavLink>
                                </li> */}
                                <li className='nav_list_n explore_btn_n category_wrapper_n'>
                                        <div className='nav_link_n explore_link_n'>{t("dashboard_edit_form.category")}
                                                <span className='icon'> <BsChevronDown/> </span></div>
                                        <FeatureDropDownCategory category_list={category_list}/>
                                </li>
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n'
                                                 to='/ebooks?auth=false'>{t("non_user_nav.browse")}</NavLink>
                                </li>
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n'
                                                 to='/explore'>{t("non_user_nav.explore")}</NavLink>
                                </li>
                                {/* <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to = '/frequently-asked-question' >{ t("non_user_nav.faq") }</NavLink>
                                </li> */}
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n'
                                                 to='/tour/guide'>{t("non_user_nav.guide")}</NavLink>
                                </li>
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to='/about-us'>About</NavLink>
                                </li>
                                <li className='nav_list_n'>
                                        <a className='nav_link_n'
                                           target="_blank"
                                           rel="noopener noreferrer"
                                           href='https://hooyia.net/fr/training/'>{t("non_user_nav.training")}</a>
                                </li>
                                <li className='nav_list_n'>
                                        <NavLink className='nav_link_n' to='/login'>{t("non_user_nav.login")}</NavLink>
                                </li>
                                <li className='nav_list_n language_drop_container'>
                                        <NavLink className='nav_link_n'>{t("non_user_nav.language")}</NavLink>
                                        <div className='language_dropdown_n'>
                                                <ul>
                                                        {
                                                                languages.map((language, index) => (
                                                                    <li key={index} className="dropdownNav-item">
                                                                            <span
                                                                                onClick={(e) => changeLang(language.code, e)}
                                                                                className={`flag-icon flag-icon-${language.flag}`}></span>
                                                                            <span
                                                                                onClick={(e) => changeLang(language.code, e)}
                                                                                className='blacktext'>{language.name}</span>
                                                                    </li>
                                                                ))
                                                        }
                                                </ul>
                                        </div>
                                </li>
                                <li className='nav_list_n signup_li'>
                                        <NavLink className='nav_link_n signup_btn_n'
                                                 to='/register'>{t("non_user_nav.signup")}</NavLink>
                                </li>
                        </ul>

                </div>
        </div>

    </div>
  )
}

export default Nav

import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { usePagination } from "../../../Context/PaginationContext";
import './pagination.css'
import {useTranslation} from 'react-i18next'

const Pagination = () => {
    const { t } = useTranslation();
    const {
        limit,
        offset,
        totalItems,
        handlePrevPage,
        handleNextPage,
        handlePageClick,
    } = usePagination();

    const currentPage = Math.floor(offset / limit) + 1;
    const totalPages = Math.ceil(totalItems / limit);

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 3;
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };


    return (
        <div className="pagination-container">
            <button
                onClick={handlePrevPage}
                disabled={offset === 0}
                className={`pagination-button ${offset === 0 ? "disabled-page" : ""}`}
            >
                <FaChevronLeft />{t("prev_btn")}
            </button>
            {generatePageNumbers().map((page) => (
                <button
                    key={page}
                    onClick={() => handlePageClick((page - 1) * limit)}
                    className={`page-number ${currentPage === page ? "active-page" : ""}`}
                >
                    {page}
                </button>
            ))}
            <button
                onClick={handleNextPage}
                disabled={offset + limit >= totalItems}
                className={`pagination-button ${offset + limit >= totalItems ? "disabled-page" : ""}`}
            >
                {t("next_btn")}<FaChevronRight />
            </button>
        </div>
    );
};

export default Pagination;

import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react'
import './Home.css'
import { HiChevronDown, HiOutlineLocationMarker } from 'react-icons/hi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ImageDiscount from './ImageDiscount'
import { FiArrowRight } from 'react-icons/fi'
import Off from '../Addons/Off'
import ProductGrid from '../Products/ProductGrid'
import { getRequest } from '../../Resquest'
import { useTranslation } from 'react-i18next'
import { usePagination } from "../../Context/PaginationContext";
import Pagination from '../Addons/Pagination/pagination';
import { tabTitle } from '../..'
import LazyLoader from '../Addons/LazyLoader'


const Home = ({ category_list, fetching_cat }) => {
	const [ebooks, setEbooks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	tabTitle("HooYia Ebooks - achetez et vendez des livres de qualité en Afrique et dans le monde")

	const {
		limit,
		offset,
		setTotalItems,
	} = usePagination();

	const fetchBooks = async () => {
		setIsLoading(true);

		const category_id = location.state?.dir || "";
		const url = category_id
			? `/public/ebook/display/?categories=${category_id}&limit=${limit}&offset=${offset}`
			: `/public/ebook/display/?limit=${limit}&offset=${offset}`;

		try {
			const response = await getRequest(url);
			if (response.status === 200) {
				saveData(response.data);
			}
		} catch (error) {
			console.error("Erreur lors de la récupération des livres :", error);
		} finally {
			setIsLoading(false);
		}
	};


	const saveData = (data) => {
		setEbooks(data?.results || []);
		setTotalItems(data?.count || 0);

	};


	useEffect(() => {
		fetchBooks();
	}, [offset, location.state?.dir]);


	useEffect(() => {
		if (location?.state?.logout) {
			window.location.reload();
		}
	}, [location]);


	const discountedProduct = ebooks.filter(
		(ebook) => ebook?.discount_percentage > 2
	);
	const dp1 = discountedProduct.slice(0, 3);
	const dp2 = discountedProduct.slice(3);

	// Catégories principales
	const category_item_firsts = category_list.slice(0, 10);
	const secondArray = category_list.slice(10);

	const { t } = useTranslation()
	const MoreCategories = lazy(() =>import('./MoreCategories'))
	return (
		<div className='container'>
			<div className='home_category_list'>
				<ul>
					{
						category_item_firsts?.map(cat => (
							<li key={ cat.pk } className='category_item'>
								<a onClick={()=> navigate('/ebooks?category='+ cat?.name, {state: {"dir": cat?.name}})} >{ cat?.name }{ cat?.children.length > 0 && <span className='cat_dropdown_indicator'><span><HiChevronDown /></span></span> }</a>
								{
									cat?.children.length > 0 && (
										<div className='drop_cat_list home'>
											{
											cat?.children?.map(child_cat => (
												<li key={child_cat } className='category_item'>
													<a onClick={()=> navigate(`/ebooks?category=${cat?.name}-${child_cat}`, {state: {"dir": cat?.name}})} >{ child_cat }</a>
												</li>
											))
											}
										</div>
									)

								}
							</li>
						))
					}
					{
						!isLoading && ( <Suspense fallback={ <LazyLoader/> }>
											<MoreCategories car_list={ secondArray } />
										</Suspense> )
					}

				</ul>
			</div>
			<div className='deals'>
				<div className='span_device'>
				<div class="ribbon ribbon-top-left"><span>{t('ebooks.best_plans')}</span></div>
					<div className='categories'>
						<div className='head'>
							{t('ebooks.mobile_best_plans')}
						</div>
						<div className='category responsive_display'>
							{/* <div className='desc'>{ t("ebooks.recommended_for_you") }</div> */}
							<LoadSuperDeals deals={dp1} isloading={isLoading} />
						</div>
						<div className='category responsive_display'>
							{/* <div className='desc'>{ t("ebooks.limited_time_low") }</div> */}
							<LoadSuperDeals deals={dp2} isloading={isLoading} />
						</div>
						{/* <div className='category responsive_display'>
							<LoadSuperDeals deals={dp3} isloading={isloading} />
						</div> */}
						<div className='category category_view_more'>
							<div className='desc'>{ t("ebooks.view_more") } <span className='icon'> <FiArrowRight /> </span></div>
						</div>
					</div>
				</div>
			</div>

			<div className='more_to_love'>
				<div className='head '>{ t("ebooks.more_to_love") }</div>
				<ProductGrid products={ebooks} isloading={isLoading} />
			</div>
			<div className='pagination_btn_wrapper'>
				 <Pagination />
			</div>
		</div>
	)
}

export default Home




const LoadSuperDeals = ({ deals, isloading }) => {

	let placeholder_dp = new Array(3).fill(0)
	return (
		<>
		{
			isloading ?
			placeholder_dp.map((i, index) => (
			<div key={index}  style={{ width: '100%' }}>
				<Link className='discountProduct'>
					<div className='imageWrapper loading_skeleton'></div>
					<div>
						<span className='discount_product_name loading_skeleton skeleton_text'/>
						<p className='price loading_skeleton skeleton_text'></p>
						<span className='price-discount skeleton_off loading_skeleton'></span>
					</div>
				</Link>
			</div>
			))
			:
			deals.map((product, index) => (
				<div key={index} style={{ width: '100%' }}>
					<SuperDealProductCalc product={product} />
				</div>
			))

		}
		</>
	)
}


// calculation of superdeal product is done here

const SuperDealProductCalc = ({ product }) => {
	const [demoPrice, setDemoPrice] = useState(product?.price);
	useLayoutEffect(() => {
		let updated_price = product.discount_percentage > 0 && parseInt(product.price) + (parseInt(product.discount_percentage)/100) * product.price
		setDemoPrice(updated_price)
	}, []);


	return (
		<Link className='discountProduct' to={`/ebooks/${product.slug}`}>
			<ImageDiscount image={product.cover} />
			<div className='discount_info_section' >
				<span className='discount_product_name'>{truncateString(product.name)}</span>
				<p>
					<span className='price' style={{ marginRight: '10px'}}>XAF {product.price}</span>
					<span className='price priceOld'>XAF { demoPrice }</span>
				</p>
				<Off discount={product.discount_percentage} />
			</div>
		</Link>
	)
}

export 	const truncateString = (inputString) => {
	// Vérifie si la chaîne est inférieure ou égale à 30 caractères
	if (inputString.length <= 30) {
		return inputString;
	} else {
		// Retourne les 30 premiers caractères suivis de "..."
		return inputString.slice(0, 30) + "...";
	}
}
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import book from './book.jpg'
import { FiDownload, FiShare2 } from 'react-icons/fi'
import Rating from '../../../Addons/Rating'
import { AnimatePresence , motion } from 'framer-motion'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { VscChromeClose } from 'react-icons/vsc'
import { useTranslation } from 'react-i18next'

const variants = {
	hidden: {
		y: "10vh",
		opacity: 0,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		}
	},
	visible: {
		y: "0vh",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		}
	},
	exit: {
		y: "10vh",
		opacity: 0,

	}
}


const SellerBook = ({ book }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [copied, setCopied] = useState(false)
	const navigate = useNavigate()

		
	const downloadBook = (e) => {
			e.stopPropagation()
	}

	const copyLink = () => {
		setShowMenu(false)
		const link = window.location.origin + '/ebooks/' + book?.slug
		navigator.clipboard.writeText(link)
		setCopied(true)
		setShowMenu(false)
		setTimeout(() => {
			setCopied(false)
		}, 3000);
	}
	const shareLink = (e) => {
			e.stopPropagation()
			copyLink()
	}

	const { t } = useTranslation()
  return (
        <>
        <div draggable onClick={() =>navigate('/book/id')} className='my_book_item'>
                <div className='book_profile_pic'>
                        <img src={book?.cover} alt={book?.name} />
                </div>
                <div className='book_profile_desc'>
                        <h2 className='book_name'>{book?.name}</h2>
                        <p className='bold'>By: {book?.author}</p>
                        <p onClick={(e) =>shareLink(e)} className='small'><span className='icon'><FiShare2 /></span>share</p>
                        <p onClick={(e) => downloadBook(e)} className='small'><FiDownload /> Download</p>
                </div>
                <div className='leave_review'>{ book?.visibility === 'V' ? <Rating value = { book?.avg_rating } /> : ""}</div>
                <span className={`color-dot ${book?.visibility === 'V' ?'color-dot-reviewed' : book?.visibility === 'R'? 'color-dot-reject': ''}`}></span>
        </div>
        <AnimatePresence
				initial={false}
				mode='wait'
			>
				{
					copied && (
						<div className='book_modalWrapper'>
							<motion.div
								className='book_notify_modal'
								variants={variants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<span className='checkmark_book'><BsFillCheckCircleFill /></span>
								<p className='modal_notify_text'>{ t("alerts.copied") }</p>
								<span onClick={() => setCopied(false)}><VscChromeClose /></span>
							</motion.div>
						</div>
					)
				}
				
			</AnimatePresence>
        </>
  )
}

export default SellerBook



import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { tabTitle } from '../..'
import './privacy.css'

const PrivacyPolicy = () => {
  const [lang, setLang] = useState('fr')

  tabTitle("Politique de confidentialité de HooYia")
  useEffect(() => {
    setLang(i18next.language)
  }, [])
  
  return (
    <>
    {
      lang === 'en'
      ?
      <PrivacyPolicyEN />
      :
      <PrivacyPolicyFR />
    }
    </>
  )
}

export default PrivacyPolicy



const PrivacyPolicyEN = () => {
  return (
    <div className='container privacy_policy'>
      <h1>PRIVACY POLICY</h1>
      <p className='date_update'>Last updated on August 20, 2024</p>

      <p>This privacy policy for HooYia ("we," "our," or "us") describes how and why we may collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as:</p>
      <ul>
        <li>Visiting and using our website (https://e-hooyia.com)</li>
        <li>Interacting with us in any other way, including during sales, marketing, or events.</li>
      </ul>

      <p>Questions or concerns? Reading this privacy policy will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>

      <h2>SUMMARY OF KEY POINTS</h2>
      <p className='summary_intro'>The following summary presents the key points of our privacy policy, but you can find more details on each of these topics by reading the full privacy policy below.</p>

      <ol>
        <li><a href="#section1">INFORMATION WE COLLECT</a></li>
        <li><a href="#section2">HOW WE PROCESS YOUR INFORMATION</a></li>
        <li><a href="#section3">LEGAL BASIS FOR PROCESSING</a></li>
        <li><a href="#section4">SHARING YOUR PERSONAL INFORMATION</a></li>
        <li><a href="#section5">RETENTION OF YOUR INFORMATION</a></li>
        <li><a href="#section6">PROTECTION OF YOUR INFORMATION</a></li>
        <li><a href="#section7">YOUR PRIVACY RIGHTS</a></li>
        <li><a href="#section8">MINOR USERS</a></li>
        <li><a href="#section9">UPDATES TO THIS POLICY</a></li>
        <li><a href="#section10">CONTACT US</a></li>
      </ol>

      <h2 id="section1">1. INFORMATION WE COLLECT</h2>
      <p>Personal information you provide to us</p>

      <p>We collect personal information that you voluntarily provide to us when you register for the Services, express an interest in obtaining information about us or our products and services, participate in activities on the Services, or contact us in any other way.</p>

      <p>The personal information we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include:</p>
      <ul>
        <li>Usernames</li>
        <li>User IDs</li>
        <li>User email addresses</li>
        <li>Phone numbers</li>
        <li>Addresses and location</li>
        <li>Passwords</li>
        <li>Bank information</li>
        <li>Mobile payment information</li>
      </ul>

      <p>Some information, such as your IP address and/or certain characteristics of your web browser, is automatically collected when you visit our Services.</p>

      <h2 id="section2">2. HOW WE PROCESS YOUR INFORMATION</h2>
      <p>We process your information for the following purposes:</p>
      <ul>
        <li>To provide you with the Services</li>
        <li>To send you marketing communications</li>
        <li>To administer and manage our operations</li>
        <li>To respond to your requests or questions</li>
        <li>To improve our services or develop new products</li>
        <li>To detect and prevent fraud</li>
        <li>To ensure legal compliance</li>
      </ul>

      <h2 id="section3">3. LEGAL BASIS FOR PROCESSING</h2>
      <p>We process your personal information on the following legal bases:</p>
      <ul>
        <li>Your consent</li>
        <li>The performance of a contract with you</li>
        <li>Our legitimate interests, which do not override your fundamental rights and freedoms</li>
        <li>Compliance with our legal obligations</li>
      </ul>

      <h2 id="section4">4. SHARING YOUR PERSONAL INFORMATION</h2>
      <p>We do not sell or rent your personal information to third parties for marketing purposes. We only share your personal information with third parties in the following circumstances:</p>
      <ul>
        <li>With your consent</li>
        <li>To comply with legal obligations</li>
        <li>To protect and defend our rights and property</li>
        <li>To respond to legal requests</li>
        <li>To execute our contracts</li>
        <li>To facilitate or negotiate a merger, acquisition, sale of company assets, or reorganization</li>
      </ul>

      <p>We use Google Search to track visits to our site. This use may involve the collection of certain data by Google in accordance with their own privacy policies.</p>

      <h2 id="section5">5. RETENTION OF YOUR INFORMATION</h2>
      <p>We retain your personal information for a period of one year from your last interaction with our Services, unless a longer retention period is required or permitted by law (for example, for tax, accounting, or other legal reasons).</p>

      <h2 id="section6">6. PROTECTION OF YOUR INFORMATION</h2>
      <p>We have implemented appropriate security measures to prevent any loss, misuse, unauthorized access, disclosure, alteration, or destruction of your personal information. Bank and mobile payment information is encrypted and securely stored in our database.</p>

      <h2 id="section7">7. YOUR PRIVACY RIGHTS</h2>
      <p>You have certain rights regarding your personal information, such as the right to request access, rectification, deletion, or limitation of the use of your personal information. It is important to note that these rights apply to all users, and we must respect and facilitate the exercise of these rights whenever you wish, either through self-service options provided by the organization or by contacting our privacy or customer support teams.</p>

      <h2 id="section8">8. MINOR USERS</h2>
      <p>Our Services are intended for users aged 18 and above. We do not knowingly collect personal information from individuals under 18 years of age. If we learn we have collected or received personal information from an individual under 18 years of age, we will delete that information.</p>

      <h2 id="section9">9. UPDATES TO THIS POLICY</h2>
      <p>We may update this privacy policy from time to time. The updated version will be indicated by an updated revision date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you the updated policy. We encourage you to review this privacy policy frequently to stay informed about how we are protecting your information.</p>

      <h2 id="section10">10. CONTACT US</h2>
      <p>If you have any questions or comments about this policy, you can contact our Data Protection Officer by email at <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>
    </div>
  )
}


const PrivacyPolicyFR = () => {
  return (
    <div className='container privacy_policy'>
      <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
      <p className='date_update'>Dernière mise à jour le 20 août 2024</p>

      <p>Cette politique de confidentialité pour HooYia ("nous," "notre," ou "nos") décrit comment et pourquoi nous pouvons collecter, stocker, utiliser et/ou partager ("traiter") vos informations lorsque vous utilisez nos services ("Services"), tels que :</p>
      <ul>
        <li>Visiter et utiliser notre site web (https://e-hooyia.com)</li>
        <li>Interagir avec nous de toute autre manière, y compris lors de ventes, de marketing ou d'événements.</li>
      </ul>

      <p>Questions ou préoccupations ? Lire cette politique de confidentialité vous aidera à comprendre vos droits et choix en matière de confidentialité. Si vous n'acceptez pas nos politiques et pratiques, veuillez ne pas utiliser nos Services. Si vous avez encore des questions ou des préoccupations, veuillez nous contacter à <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>

      <h2>RÉSUMÉ DES POINTS CLÉS</h2>
      <p className='summary_intro'>Le résumé suivant présente les points clés de notre politique de confidentialité, mais vous pouvez obtenir plus de détails sur chacun de ces sujets en lisant la politique de confidentialité complète ci-dessous.</p>

      <ol>
        <li><a href="#section1">INFORMATIONS QUE NOUS COLLECTONS</a></li>
        <li><a href="#section2">COMMENT NOUS TRAITONS VOS INFORMATIONS</a></li>
        <li><a href="#section3">BASE LÉGALE DU TRAITEMENT</a></li>
        <li><a href="#section4">PARTAGE DE VOS INFORMATIONS PERSONNELLES</a></li>
        <li><a href="#section5">CONSERVATION DE VOS INFORMATIONS</a></li>
        <li><a href="#section6">PROTECTION DE VOS INFORMATIONS</a></li>
        <li><a href="#section7">VOS DROITS EN MATIÈRE DE CONFIDENTIALITÉ</a></li>
        <li><a href="#section8">UTILISATEURS MINEURS</a></li>
        <li><a href="#section9">MISES À JOUR DE CETTE POLITIQUE</a></li>
        <li><a href="#section10">CONTACTEZ-NOUS</a></li>
      </ol>

      <h2 id="section1">1. INFORMATIONS QUE NOUS COLLECTONS</h2>
      <p>Informations personnelles que vous nous fournissez</p>

      <p>Nous collectons les informations personnelles que vous nous fournissez volontairement lorsque vous vous inscrivez aux Services, exprimez un intérêt pour obtenir des informations sur nous ou nos produits et services, participez à des activités sur les Services ou nous contactez de toute autre manière.</p>

      <p>Les informations personnelles que nous collectons dépendent du contexte de vos interactions avec nous et les Services, des choix que vous faites et des produits et fonctionnalités que vous utilisez. Les informations personnelles que nous collectons peuvent inclure :</p>
      <ul>
        <li>Noms d'utilisateur</li>
        <li>Identifiants utilisateur</li>
        <li>Adresses email utilisateur</li>
        <li>Numéros de téléphone</li>
        <li>Adresses et localisation</li>
        <li>Mots de passe</li>
        <li>Informations bancaires</li>
        <li>Informations sur les paiements mobiles</li>
      </ul>

      <p>Certaines informations, telles que votre adresse IP et/ou certaines caractéristiques de votre navigateur web, sont automatiquement collectées lorsque vous visitez nos Services.</p>

      <h2 id="section2">2. COMMENT NOUS TRAITONS VOS INFORMATIONS</h2>
      <p>Nous traitons vos informations pour les fins suivantes :</p>
      <ul>
        <li>Pour vous fournir les Services</li>
        <li>Pour vous envoyer des communications marketing</li>
        <li>Pour administrer et gérer nos opérations</li>
        <li>Pour répondre à vos demandes ou questions</li>
        <li>Pour améliorer nos services ou développer de nouveaux produits</li>
        <li>Pour détecter et prévenir la fraude</li>
        <li>Pour assurer la conformité légale</li>
      </ul>

      <h2 id="section3">3. BASE LÉGALE DU TRAITEMENT</h2>
      <p>Nous traitons vos informations personnelles sur les bases légales suivantes :</p>
      <ul>
        <li>Votre consentement</li>
        <li>L'exécution d'un contrat avec vous</li>
        <li>Nos intérêts légitimes, qui ne prévalent pas sur vos droits fondamentaux et libertés</li>
        <li>Le respect de nos obligations légales</li>
      </ul>

      <h2 id="section4">4. PARTAGE DE VOS INFORMATIONS PERSONNELLES</h2>
      <p>Nous ne vendons ni ne louons vos informations personnelles à des tiers à des fins de marketing. Nous ne partageons vos informations personnelles avec des tiers que dans les circonstances suivantes :</p>
      <ul>
        <li>Avec votre consentement</li>
        <li>Pour se conformer aux obligations légales</li>
        <li>Pour protéger et défendre nos droits et propriétés</li>
        <li>Pour répondre aux demandes légales</li>
        <li>Pour exécuter nos contrats</li>
        <li>Pour faciliter ou négocier une fusion, une acquisition, une vente d'actifs de l'entreprise, ou une réorganisation</li>
      </ul>

      <p>Nous utilisons Google Search pour suivre les visites sur notre site. Cette utilisation peut impliquer la collecte de certaines données par Google conformément à leurs propres politiques de confidentialité.</p>

      <h2 id="section5">5. CONSERVATION DE VOS INFORMATIONS</h2>
      <p>Nous conservons vos informations personnelles pendant une période d'un an à compter de votre dernière interaction avec nos Services, sauf si une période de conservation plus longue est requise ou autorisée par la loi (par exemple, pour des raisons fiscales, comptables ou autres légales).</p>

      <h2 id="section6">6. PROTECTION DE VOS INFORMATIONS</h2>
      <p>Nous avons mis en place des mesures de sécurité appropriées pour prévenir toute perte, utilisation abusive, accès non autorisé, divulgation, altération ou destruction de vos informations personnelles. Les informations bancaires et de paiement mobile sont cryptées et stockées en toute sécurité dans notre base de données.</p>

      <h2 id="section7">7. VOS DROITS EN MATIÈRE DE CONFIDENTIALITÉ</h2>
      <p>Vous avez certains droits concernant vos informations personnelles, tels que le droit de demander l'accès, la rectification, la suppression ou la limitation de l'utilisation de vos informations personnelles. Il est important de noter que ces droits s'appliquent à tous les utilisateurs, et nous devons respecter et faciliter l'exercice de ces droits chaque fois que vous le souhaitez, soit par le biais d'options d'auto-service fournies par l'organisation, soit en contactant nos équipes de soutien à la confidentialité ou à la clientèle.</p>

      <h2 id="section8">8. UTILISATEURS MINEURS</h2>
      <p>Nos Services sont destinés aux utilisateurs âgés de 18 ans et plus. Nous ne collectons pas sciemment d'informations personnelles auprès de personnes de moins de 18 ans. Si nous apprenons avoir collecté ou reçu des informations personnelles d'une personne de moins de 18 ans, nous supprimerons ces informations.</p>

      <h2 id="section9">9. MISES À JOUR DE CETTE POLITIQUE</h2>
      <p>Nous pouvons mettre à jour cette politique de confidentialité de temps en temps. La version mise à jour sera indiquée par une date de révision mise à jour, et la version mise à jour sera effective dès qu'elle sera accessible. Si nous apportons des modifications importantes à cette politique de confidentialité, nous pouvons vous en informer en affichant un avis de ces changements ou en vous envoyant directement la politique mise à jour. Nous vous encourageons à consulter régulièrement cette politique de confidentialité pour rester informé sur la manière dont nous protégeons vos informations.</p>

      <h2 id="section10">10. CONTACTEZ-NOUS</h2>
      <p>Si vous avez des questions ou des commentaires sur cette politique, vous pouvez contacter notre Responsable de la Protection des Données par email à <a href="mailto:contact@e-hooyia.com">contact@e-hooyia.com</a>.</p>
    </div>
  )
}
/**
 * Check if the phone number respects the limits claimed.
 * @param {string} phoneNumber - phone number to validate.
 * @returns {boolean} - return `true` if the phone number is valid, and `false` otherwise.
 */
export const PhoneValidation = (phoneNumber) => {
    // Check if phoneNumber is null or undefined
    if (!phoneNumber) {
        return false; // Return false for invalid input
    }

    // Handle empty string separately
    if (phoneNumber === "") {
        return true;
    }

    // Clean up the phone number by removing spaces and parentheses
    const cleanedNumber = phoneNumber.replace(/[()\s]/g, "");

    // Check if the number starts with "+" followed by digits only
    const validFormat = /^\+\d+$/.test(cleanedNumber);

    if (!validFormat) {
        return false;
    }

    // Remove the "+" prefix to validate only the digits
    const numericPart = cleanedNumber.slice(1); // Remove the "+"

    // Check if the digit count is between 6 and 15
    const digitCount = numericPart.length;
    return digitCount >= 6 && digitCount <= 15;
};

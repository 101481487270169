import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LazyImage from "../Addons/LazyImage";
import { truncateString } from "../Home/Home";
import { BsStarFill } from "react-icons/bs";

const Ebook = ({ product }) => {

	const { t } = useTranslation()
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<Link key={product.slug} to={`/ebooks/${product.slug}`} className='home_grid_item'
			  onMouseEnter={() => setShowTooltip(true)}
			  onMouseLeave={() => setShowTooltip(false)}>
			<div className='imgWrapper'>
				<LazyImage image={product?.cover} />
			</div>
			<div className='desc'>
				<p>
					{
						product?.price === '0.00'?
							<span className='price'>{ t("ebooks.free") }</span>
						:
						(
							<>
								<span className='price'>XAF {product.price}</span>
								{/* <span className='price'>XAF {product.discount_percentage > 0 ? (product?.discount_percentage/100) * product.price : product.price}</span> */}
								{/* <span className='price priceOld'>XAF {product.price}</span> */}
							</>
						)
					}
				</p>
				<p className='sold-rating'>
					<span className='sold'>0+ { t("ebooks.sold") }</span>
					<span className='rating'>{product?.avg_rating.toFixed(1)}<span className='icon'><BsStarFill /></span></span>
				</p>
				<p className='remove_mobile'>
					{
						product?.taget_list.length > 1 ? (
							<>
								<span className='top_selling_tag'>{ product?.taget_list[0] }</span>
								<span className='top_selling_tag most_baught'>{product?.taget_list[1]}</span>
							</>
						) : (
							<span className='top_selling_tag most_baught'>{product?.category}</span>
						)
					}
				</p>
				<p className='discount_product_name'>{truncateString(product?.name)}</p>
			</div>
			{showTooltip && (
				<div className="tooltip">{product?.name}</div>
			)}
		</Link>
	)
}

export default Ebook;
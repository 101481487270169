import React from 'react';
import {FaCreditCard, FaHandHoldingUsd, FaMoneyBill} from "react-icons/fa";

const Header = () => {
    return (
        <div className="transaction_grid">
            <div className="grid_item">
                <div className="grid_item_card">
                    <div className="card_item_title">
                        <div className="item-icon">
                            <FaMoneyBill color="fff"/>
                        </div>
                        <h2>Current balance</h2>
                    </div>
                    <div className="card_details">
                        <span>Available</span>
                        <p>$435,220,00 XAF</p>
                    </div>
                </div>
            </div>
            <div className="grid_item">
                <div className="grid_item_card">
                    <div className="card_item_title">
                        <div className="item-icon">
                            <FaHandHoldingUsd color="fff"/>
                        </div>
                        <h2>Total Received</h2>
                    </div>
                    <div className="card_details">
                        <span>+20% compared to last month</span>
                        <p>$2,176,221 XAF</p>
                    </div>
                </div>
            </div>
            <div className="grid_item">
                <div className="grid_item_card">
                    <div className="card_item_title">
                        <div className="item-icon">
                            <FaCreditCard color="fff"/>
                        </div>
                        <h2>Total Sent or Spent</h2>
                    </div>
                    <div className="card_details">
                        <span>Updated 5 minutes ago.</span>
                        <p>$4,220,00 XAF</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
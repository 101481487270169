import React, { useContext, useLayoutEffect, useState } from 'react'
import { UserContext } from '../../Context/AuthContext';
import jwt_decode from "jwt-decode";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useNavigate } from 'react-router';

import { VscChromeClose } from 'react-icons/vsc'


import './Auth.css'
import GoogleButton from '../NON-USER/Auth/OAuth/GoogleButton';
import FacebookButton from '../NON-USER/Auth/OAuth/FaceBookButton';
const Login = () => {
        const [user, setUser] = useContext(UserContext);
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        // const [showModal, setshowModal] = useState(false);
        
        
        
        const sendUser = () => {

        }
        const navigate = useNavigate()
        useLayoutEffect(() => {
                  
          return () => {
            validateToken()
          };
        }, []);

        const validateToken = () => {
                const decoded_token = jwt_decode(user.access);

                checkToken(decoded_token.exp) ? redirect() : requestRefresh()
        }
        const checkToken = (time) => {
                const current = new Date().getTime()
                return false
        }
        const requestRefresh = () => {
                
        }
        const redirect = () => {
                navigate('/')
        }
  return (
    <div className='user_auth_popup'>
        <div onClick={redirect} className='shadow_user' />
        <div className='auth_u'>
                <div className='loginModal_head'>
                        <p className='title'>welcome back. login to continue</p>
                </div>
                <div onClick={redirect} className='closeModal'><VscChromeClose /></div>
                <form className='authForm' onSubmit={sendUser}>
                        <div className='inputContainer'>
                                <input  value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Email Address' />
                        </div>
                        <div className='inputContainer'>
                                <input  value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder='Password' />
                        </div>
                        <div className='loginBtn'>
                                <input type='submit' className='submit' value="Login" />
                        </div>
                </form>
                <div className="buttonWrapper signupBtn">
                        <p className="or">OR</p>
                        <div className='other_autheticate'>
                                <div className='authBtn'>
                                        <GoogleButton />
                                </div>
                                <div className='authBtn'>
                                        <FacebookButton />
                                </div>
                                <div className='authBtn'>
                                        <GoogleButton />
                                </div>
                        </div>
                </div>
        </div>
    </div>
  )
}

export default Login

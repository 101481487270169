import React from 'react';
import './transaction.css';
import { TransactionData } from "./Data";
import DataTable from 'react-data-table-component';
import Header from '../transactionHistory/components/Header';

const TransactionHistory = () => {

    return (
        <div className="main_transaction">
            <div className="transaction_container">
                 <h1 className="transaction_title">Balance & Transaction History</h1>
                <Header />
                <h2 className="transaction_title">Transactions</h2>
                <DataTable
                    columns={[
                        {
                            name: <div className="row_item_stat row_item_header_stat">Transaction ID</div>,
                            selector: (row) => <div className="row_item_stat">{row.id}</div>,
                            sortable: true,
                            center: true
                        },
                        {
                            name: <div className="row_item_stat row_item_header_stat">Date</div>,
                            selector: (row) => <div className="row_item_stat">{row.date}</div>,
                            sortable: true,
                            center: true
                        },
                        {
                            name: <div className="row_item_stat row_item_header_stat">Status</div>,
                            selector: (row) => (
                                <div
                                    className={`row_item_stat status ${row.status.toLowerCase()}`}
                                >
                                    {row.status}
                                </div>
                            ),
                            sortable: true,
                            center: true
                        },
                        {
                            name: <div className="row_item_stat row_item_header_stat">Amount ($)</div>,
                            selector: (row) => <div className="row_item_stat">{row.amount}</div>,
                            sortable: true,
                            center: true
                        }
                    ]}
                    data={TransactionData}
                    pagination
                    striped
                    selectableRowsHighlight
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="400px"
                    responsive
                />

            </div>
        </div>
    );
};

export default TransactionHistory;
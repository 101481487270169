import React, {useEffect, useLayoutEffect, useState} from 'react'
import MyBook from './MyBook'
import './MyBooks.css'
import book from './book.jpg'
import {getRequest} from '../../Resquest'
import {FiLoader} from 'react-icons/fi'
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Pagination from "../Addons/Pagination/pagination";
import {BsArrowLeft} from 'react-icons/bs'
import {usePagination} from "../../Context/PaginationContext";

const MyBooks = () => {
    const [books, setBooks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [filteredBooks, setFilteredBooks] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { t } = useTranslation();


    const { limit, offset, setTotalItems } = usePagination();

    const fetchMyBooks = async () => {
        setIsLoading(true);
        try {
            const res = await getRequest(`/customer/ebook/customer-ebook/?limit=${limit}&offset=${offset}`);
            if (res.status === 200) {
                setBooks(res.data.results);
                setTotalItems(res.data.count);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des livres");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!searchQuery) {
                setFilteredBooks(books);
            } else {
                const lowerCaseQuery = searchQuery.toLowerCase();
                const filtered = books.filter(
                    (book) =>
                        book.ebooks.length > 0 &&
                        book.ebooks[0].name.toLowerCase().includes(lowerCaseQuery)
                );
                setFilteredBooks(filtered);
            }
        }, 300)
        return () => clearTimeout(timeoutId);
    }, [searchQuery, books]);

    useEffect(() => {
        fetchMyBooks();
    }, [offset, limit]);

    return (
        <div className='mybooks_page'>
            <main className='main_user_section'>
                <div className='title paid_books_title'>
                    <div className="books_sub_heading">
                        <p className="paid_title">{t("baught_books.title")}</p>
                        <input type="text" className="search_book_store" placeholder={t('baught_books.placeholder')}
                               value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                               aria-label="Search books"
                        />
                    </div>

                    <Link to='/'><BsArrowLeft/> {t("randoms.go_back")}</Link>
                </div>
                <div className="user_main_grid">
                    {isLoading ? (
                        <div className="loader-container">
                            <FiLoader className="loader-icon"/>
                        </div>
                    ) : filteredBooks.length < 1 ? (
                        <div className="seller_with_no_book">
                            <div className="title">{t("baught_books.alert_no_book")}</div>
                            <button onClick={() => navigate("/ebooks")}>{t("baught_books.btn")}</button>
                        </div>
                    ) : (
                        filteredBooks.map((book) => <MyBook key={book?.transaction_id} book={book?.ebooks[0]}/>)
                    )}
                </div>
                <Pagination/>
            </main>
        </div>
    )
}

export default MyBooks
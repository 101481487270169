import React from 'react';
import './LazyLoader.css';

const LazyLoader = () => {
  return (
    <div className='loader-container'>
        <div className="spinner"></div>
    </div>
  )
}

export default LazyLoader
import React, { createContext, useContext, useState } from "react";

const PaginationContext = createContext();

export const usePagination = () => {
    const context = useContext(PaginationContext);
    if (!context) {
        throw new Error("usePagination must be used within a PaginationProvider");
    }
    return context;
};

export const PaginationProvider = ({ children }) => {
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const handlePrevPage = () => {
        if (offset > 0) {
            setOffset((prev) => prev - limit);
        }
    };

    const handleNextPage = () => {
        if (offset + limit < totalItems) {
            setOffset((prev) => prev + limit);
        }
    };
    const handlePageClick = (newOffset) => setOffset(newOffset);

    return (
        <PaginationContext.Provider
            value={{
                limit,
                offset,
                totalItems,
                setTotalItems,
                handlePrevPage,
                handleNextPage,
                handlePageClick,
            }}
        >
            {children}
        </PaginationContext.Provider>
    );
};

export const TransactionData = [
    { "id": "TXN001", "date": "2024-12-01", "status": "Completed", "amount": 500 },
    { "id": "TXN002", "date": "2024-12-02", "status": "Pending", "amount": 250 },
    { "id": "TXN003", "date": "2024-12-03", "status": "Failed", "amount": 0 },
    { "id": "TXN004", "date": "2024-12-04", "status": "Completed", "amount": 1250 },
    { "id": "TXN005", "date": "2024-12-05", "status": "Refunded", "amount": -100 },
    { "id": "TXN006", "date": "2024-12-06", "status": "Completed", "amount": 700 },
    { "id": "TXN007", "date": "2024-12-07", "status": "Completed", "amount": 300 },
    { "id": "TXN008", "date": "2024-12-08", "status": "Pending", "amount": 400 },
    { "id": "TXN009", "date": "2024-12-09", "status": "Failed", "amount": 0 },
    { "id": "TXN010", "date": "2024-12-10", "status": "Completed", "amount": 1500 },
    { "id": "TXN011", "date": "2024-12-11", "status": "Refunded", "amount": -200 },
    { "id": "TXN012", "date": "2024-12-12", "status": "Completed", "amount": 800 },
    { "id": "TXN013", "date": "2024-12-13", "status": "Pending", "amount": 450 },
    { "id": "TXN014", "date": "2024-12-14", "status": "Completed", "amount": 950 },
    { "id": "TXN015", "date": "2024-12-15", "status": "Failed", "amount": 0 },
    { "id": "TXN016", "date": "2024-12-16", "status": "Completed", "amount": 1200 },
    { "id": "TXN017", "date": "2024-12-17", "status": "Pending", "amount": 600 },
    { "id": "TXN018", "date": "2024-12-18", "status": "Refunded", "amount": -50 },
    { "id": "TXN019", "date": "2024-12-19", "status": "Completed", "amount": 1300 },
    { "id": "TXN020", "date": "2024-12-20", "status": "Failed", "amount": 0 },
    { "id": "TXN021", "date": "2024-12-21", "status": "Completed", "amount": 1100 },
    { "id": "TXN022", "date": "2024-12-22", "status": "Pending", "amount": 500 },
    { "id": "TXN023", "date": "2024-12-23", "status": "Refunded", "amount": -150 },
    { "id": "TXN024", "date": "2024-12-24", "status": "Completed", "amount": 700 },
    { "id": "TXN025", "date": "2024-12-25", "status": "Completed", "amount": 850 },
    { "id": "TXN026", "date": "2024-12-26", "status": "Pending", "amount": 450 },
    { "id": "TXN027", "date": "2024-12-27", "status": "Failed", "amount": 0 },
    { "id": "TXN028", "date": "2024-12-28", "status": "Completed", "amount": 1400 },
    { "id": "TXN029", "date": "2024-12-29", "status": "Refunded", "amount": -120 },
    { "id": "TXN030", "date": "2024-12-30", "status": "Completed", "amount": 1050 },
    { "id": "TXN031", "date": "2024-12-31", "status": "Pending", "amount": 650 },
    { "id": "TXN032", "date": "2025-01-01", "status": "Completed", "amount": 750 },
    { "id": "TXN033", "date": "2025-01-02", "status": "Failed", "amount": 0 },
    { "id": "TXN034", "date": "2025-01-03", "status": "Completed", "amount": 1150 },
    { "id": "TXN035", "date": "2025-01-04", "status": "Pending", "amount": 500 },
    { "id": "TXN036", "date": "2025-01-05", "status": "Refunded", "amount": -80 },
    { "id": "TXN037", "date": "2025-01-06", "status": "Completed", "amount": 950 },
    { "id": "TXN038", "date": "2025-01-07", "status": "Pending", "amount": 600 },
    { "id": "TXN039", "date": "2025-01-08", "status": "Failed", "amount": 0 },
    { "id": "TXN040", "date": "2025-01-09", "status": "Completed", "amount": 1350 }
]
